import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgZorroAntdModule } from 'ng-zorro-antd';

@NgModule({
    declarations: [],
    imports: [ CommonModule,    
          NgZorroAntdModule,
    ],
    exports: [NgZorroAntdModule],
    providers: [],
})
export class AntModule {}