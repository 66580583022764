

import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { AntModule } from './export.module';
import { AppService } from './app.service';
import { CookieService } from 'ngx-cookie-service';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src';
import { HIGHCHARTS_MODULES } from 'angular-highcharts';
import { PubNubAngular } from 'pubnub-angular2';
import { ReverseAuthGuard } from './reverse.AuthGuard';
import { AuthGuard } from './auth.guard';
import { httpSetHeaders } from './httpinterceptor.service';

registerLocaleData(en);

@NgModule({
   declarations: [
      AppComponent,
   ],
   imports: [
      BrowserModule,
      AntModule,
      AppRoutingModule,
      HttpClientModule,
      BrowserAnimationsModule,
   ],
   providers: [{ provide: NZ_I18N, useValue: en_US }, 
      { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] },
   { provide: HTTP_INTERCEPTORS, useClass: httpSetHeaders, multi: true },
      CookieService,
      PubNubAngular,
      ReverseAuthGuard,
      AuthGuard,
      AppService],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
