import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class httpSetHeaders implements HttpInterceptor {
    
    constructor( private router:Router,
        private cookieService: CookieService,
        ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


        // if (!request.headers.has('Content-Type')) {
        //     request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
        // }

        // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

        // request = request.clone({ headers: request.headers.set('Freaky', 'Jolly') });

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    // console.log('event', event);
                   // if(event.body.extras)
                   // console.log(event.body.extras.msg)
                    if(event.body.extras.msg==6){
                      //  console.log('erer')
                        this.cookieService.delete('adminData','/')
                        this.router.navigate(['/login'])
                      //  this.router.navigateByUrl('/login')
                    }
                }
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                let data = {};
                // data = {
                //     domain: error.domain,
                //     message: error.message,
                //     reason: error.reason
                // };
                console.log(data);
                return throwError(error);
            }));
    }
}