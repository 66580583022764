import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { AppService } from './app.service';

@Injectable()
export class ReverseAuthGuard implements CanActivate {
    constructor(private _router: Router, private cookieService: CookieService,private appService:AppService) {
    }

    canActivate(){
        if (this.cookieService.check('adminData')) {
            this._router.navigateByUrl('/dashboard')
            return false;
        } else{
         return true;

        }
    }






}