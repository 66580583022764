import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { ReverseAuthGuard } from './reverse.AuthGuard';
import { AuthGuard } from './auth.guard';


const routes: Routes = [
   {path:'login',loadChildren:() => import('./login/login.module').then(m => m.LoginModule)},
   {path:'dashboard',loadChildren:() => import('./dashboard/dashboard.module').then(m => m.DashboardModule)},

   { path: '', redirectTo: 'login', pathMatch: 'full' }
];

@NgModule({
   imports: [
      RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules})
   ],
   exports: [
      RouterModule
   ],
   
})
export class AppRoutingModule { }
