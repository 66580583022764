import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private _router: Router, private cookieService: CookieService) {
    }
    canActivateChild() {
        if (this.cookieService.check('adminData')) {
            
            return true;
        } else {
            // navigate to login page
            this._router.navigateByUrl('/login')
            // you can save redirect url so after authing we can move them back to the page they requested
            return false;
        }
    }
    canActivate() {
        if (this.cookieService.check('adminData')) {
            
            return true;
        } else {
            // navigate to login page
            this._router.navigateByUrl('/login')
            // you can save redirect url so after authing we can move them back to the page they requested
            return false;
        }
    }



}