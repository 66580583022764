import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  statusChanged = new EventEmitter()
  apiMessages = [
    "",
    "Server Error",
    "Database Error",
    "Enter All Tags",
    "Invalid Api Key",
    "Invalid User",
    "Session Expired",
    "User Account Inactivated",
    "Invalid Secret Code",
    "Invalid Device",
    "Invalid File",
    "Invalid Email Format",
    "Invalid Date",
    "Submit Date",
    "Invalid Gender",
    "Image Not Found",
    "Invalid Admin",
    "Invalid Requirement Date",
    "Submit Requirement Date",
    "Invalid Expiry Date",
    "Expiry Day Must Be Atleast after One Day",
    "Serial Number Already Exist",
    "Request Size Exceeded",
    "Invalid Country",
    "Invalid City",
    "Invalid Zone",
    "Polygon Path Must Have Atleast Four Paths",
    "Submit Correct Latitude and Longitudes",
    "Name Already Exist",
    "Start Time Must Be Less than End Time",
    "Windows Already Exist on This Time",
    "Invalid Vehicle",
    "Location Not in Zone",
    "Minimum Weight Must Be Less than Maximum Weight",
    "Otp Request Exceed Try after Some Time",
    "Otp Tries Exceed Try after Some Time",
    "Invalid Otp",
    "Item Name Already Exist",
    "We Dont Serve on Pickup Location",
    "We Dont Serve on Drop Location",
    "Pickup and Drop Location Must Be in Same City",
    "Invalid Delivery Date",
    "Delivery Date Must Be Future Date",
    "Invalid Item",
    "Phone Number Already Registered",
    "Phone Number Not Registered",
    "User Doesnt Have Vendor Permissions",
    "Request Size Exceeded",
    "File Not Supported",
    "Invalid Pickup Address",
    "Invalid Drop Address",
    "Please Request Atleast One Order",
    "Invalid Request Id",
    "Invalid Weight Type",
    "Item Gross Weight Exceeded Recomended Gross Weight",
    "City Order Queue Already Processing",
    "No Non Process Orders Available for Queueing",
    "Invalid Driver",
    "Invalid Trip",
    "Trip Already Started",
    "Trip Already Completed",
    "Invalid Order Queue",
    "Invalid Trip Route",
    "Invalid Order",
    "Trip Route Already Processed",
    "Trip Route Cancelled",
    "Trip Route Otp Not Sent",
    "Trip Not Started",
    "Trip Completed",
    "Trip Reported",
    "Invalid Reason",
    "Journey Already Completed",
    "Journey Already Cancelled or Reported",
    "Driver Not Available",
    "Trip Not Reported",
    "Email Already Registered",
    "Email Not Registered",
    "Invalid Password",
    "Invalid Old Password",
    "Old Password and New Password Must Be Different",
    "Invalid Package",
    "Invalid Reference Admin",
    "Zones Not Available",
    "Zones Not Available",
    "Invalid Item Category",
    "Invalid Load Time",
    "Invalid Unload Time",
    "Please Submit Atleast One Category",
    "Invalid Driver Rating Option",
    "Base Price with Selected Options Already Exists",
    "Invalid Delivery Type",
    "Invalid Sub Trip Type",
    "Invalid Service Type",
    "Minimum Price Must Be Lesser than Maximum Price",
    "Convenience Fees Already Added for given Inputs",
    "Gst Already Added for given Inputs",
    "Base Price Gst Rule Already Added for given Inputs",
    "Invalid Base Price",
    "Invalid Gst",
    "Discount Code Already Exist",
    "Invalid Discount Type",
    "Invalid Payment Type",
    "Discount Percentage Must Be Between  and ",
    "Minimum Value Must Be Less than Maximum Value",
    "Invalid Discount",
    "DOB CANNOT BE UPCOMING DATE",
    "Minimum  Years for Signup",
    "Referral Does Not Exist",
    "Referral User Must Be Different than Signin User",
    "Invalid Contact",
    "Schedule Must Be Current or Future Date",
    "Schedule Date Is Not Available",
    "Discount Day Number Already Exist",
    "Start Date Must Be Future Date",
    "End Date Must Be Future Date",
    "Invalid Automated Offer Id",
    "Discount Percentage Must Be Between  and ",
    "Pool Day Number Already Exist",
    "Pool Driver Count Must Be Less than Available Drivers",
    "Invalid Driver Pool Setting",
    "Invalid Collection Type",
    "We Dont Serve for This Package Volume",
    "Please Provide Only Single Pickup",
    "Please Provide Only Single Drop",
    "Please Provide Only Single Package Weight",
    "Invalid convenience fee",
    "Invalid baseprice gst",//126,
    "We Dont Serve on Your Current Location",
    "Invalid Discount Code",
    "Discount Not Applicable",
    "Please Update Date of Birth for Applying Discount",
    "Discount Not Applicable Due to Your Age",
    "Invalid Price Quote",
    "Invalid User Discount",
    "Invalid Instant Order Request",
    "Instant Order Request Already Accepted",
    "Instant Order Request Cancelled",
    "Instant Order Request Expired",
    "Instant Order Request Cannot Be Cancelled since Order Placed",
    "You Cannot Place Order since Pricing Not Available for Selected Options",
    "Invalid Window",
    "Invalid User Window Slot",
    "Invalid Main Order",
    "Vehicle Number Already Exist",
    "Trip Route Already Initiated",
    "Trip Route Already Reached",
    "Trip Route Already Picked",
    "Trip Route Already Dropped",
    "Trip Route Not Initiated",
    "Trip Route Not Reached",
    "Barcode Number Already Exist",
    "Duplicate Barcode or Orderid",
    "Barcode Does Not Match the Order",
    "Razorpay Payment Failed",
    "Invalid Payment Id",
    "Razorpay Error",
    "Invalid Capture Amount",
    "Some Orders Missing for Drop",
    "Category Name Already Exist",
    "Invalid User Mapping Type",
    "Invalid Help Post",
    "Invalid Document",
    "Invalid Specail Offer",
    "Sum of Total Share Must Be ",
    "Please Recheck All the Required Values",
    "Invalid Driver Salary Rule",
    "Invalid Update",
    "Driver Contract Salary Already Linked",
    "Invalid Driver Leased Owner",
    "Leased Owner Drivers All Linked",
    "Invalid Driver Contract Salary Linked",
    "Already Rated",
    "Rating Star Must Be Between  and ",
    "You Can Only Report Pickup Journey Route",
    "We Cannot Update Slot Time",//174
    "You Have Used Maximum Available Discount",
    "You Cannot Apply This Discount",
    "Invalid Total Trip Status",
    "Beneficiary Account Number Already Exist",
    "Invalid Ifsc Code",
    "Date Must Be Future Date",
    "Office Holiday Already Added",
    "Invalid Office Holiday",
    "Account Not Active",//183
    "Invalid Payout Id",
    "Payout Already Processed",
    "Invalid Transaction",
    "Refund Already Processed",
    "Refund Already Cancelled",
    "Insurance Not Available",
    "Invalid User Insurance",
    "Invalid Insurance",
    "Invalid Excess Amount Invoice",
    "Excess Amount Must Be Collected by Online",
    "Link Already Sent",
    "Excess Amount Already Collected",
    "Main Order Delivered",
    "Main Order Cancelled",
    "Please Try after Some Time",
    "Order Setting Must Have Atleast Gig or Leased Setting",
    "Setting Already Exist",
    "Invalid Order Setting",
    "Invalid State",
    "City Already Linked",
    "Invalid Order Delivery Setting",
    "Invalid Customer Cancellation Reason",
    "Main Order Awaiting for Cancellation",
    "Orders Cannot Cancelled Due to Some Orders Have Been Picked",
    "Order Already Placed",
    "Discount Not Applicable Due to Non Referral Account",
    "Invalid Alert Type",
    "Alert Type Already Exist",
    "Invalid Alert",
    "Sms Character Length Exceeded",
    "Discount Not Applicable on First Time Order",
    "Discount Applicable Only on First Time Order",
    "Discount Applicable Only to Promotion Customers",
    "Invoice Generation Error Try Again after Some Time",//217
    "Order Not Completed",
    "Vehicle Allocated to Driver",
    "Invalid Beneficiary Account",
    "Cannot Remove Default Beneficiary Account",
    "Invalid Main Order Status",
    "Insufficient Balance",
    "Amount Not Satisfy Minimum Maximum Transaction Amount",
    "Invalid Status",
    "Invalid Campaign Type",
    "Select Atleast One Campaign",
    "Invalid Campaign",
    "Insured Amount Less than Minimum Insured Amount",
    "Insured Amount Exceeded Maximum Insured Amount",
    "You Cannot Accept New Orders",//231
    

  ]
  Main_Order_Status = [
    {
      label: 'Payment Pending',
      value: 1
    },
    {
      label: 'Orders Placed',
      value: 2
    },
    {
      label: 'Orders Tripped',
      value: 3
    },
    {
      label: 'Delivered',
      value: 4
    },
    {
      label: 'Payment Failed',
      value: 5
    },
    {
      label: 'Slot Allocation Failed',
      value: 6
    }
    ,
    {
      label: 'Order Cancelled',
      value: 7
    }
  ]
  Customer_Target_Type = [
    {
      id: 1,
      value: 'New Customer'
    },
    {
      id: 2,
      value: 'Retaing Customer'
    },
    {
      id: 3,
      value: 'All Customer'
    },
    {
      id: 4,
      value: 'Selected Customer'
    },
  ]
  dateFormat = 'dd/MM/yyyy hh:mm:ss a'
  containerList = [
    {
      key: 1, value: 'Normal Container'
    },
    {
      key: 2, value: 'ICE Container'
    },
  ]
  Selected_Instant_Order_Request_Status_Array=[
    {
      value:1,
      label:'Requested'
    },
    {
      value:2,
      label:'Accepted '
    },
    {
      value:3,
      label:'Order Placed'
    },
    {
      value:4,
      label:'Cancelled '
    },
    {
      value:5,
      label:'Drivers Not Available '
    },
  ]
 
  Sub_Trip_Type =
    [
      {
        key: '1', value: 'SP - SD',
      },
      {
        key: '2', value: 'SP - MD'
      },
      {
        key: '3', value: 'MP - SD'
      },
    ]
  Total_Trip_Status =
    [
      {
        key: '1', value: 'Initiated/Reinitiated Driver Changed'
      },
      {
        key: '2', value: 'Trip Started'
      },
      {
        key: '3', value: 'Trip Completed'
      },
      {
        key: 4, value: 'Trip Reported'
      }
    ]
  Order_Type =
    [
      {
        key: '1', value: 'Cash'
      },
      {
        key: '2', value: 'Online'
      },
      {
        key: '3', value: 'Monthly Invoice'
      },
    ]
  Driver_Type =
    [
      {
        key: '1', value: 'Gig'
      },
      {
        key: '2', value: 'Leased'
      },

    ]
  Period_Consideration =
    [
      {
        key: '1', value: 'Daily'
      },
      // {
      //   key: '2', value: 'Weekly'
      // },
      // {
      //   key: '3', value: 'Monthly'
      // },
      // {
      //   key: '4', value: 'Yearly'
      // },

    ]
  Pay_Type =
    [
      {
        key: '1', value: 'Comission Based'
      },
      {
        key: '2', value: 'FRE'
      },


    ]
  Base_Qualifier =
    [
      {
        key: '1', value: 'Task'
      },
      {
        key: '2', value: 'Kms'
      },
      // {
      //   key: '3', value: 'Weight'
      // },


    ]
  subOrderStatus = [
    {
      key: 1, value: 'Order Initiated'
    },
    {
      key: 2, value: 'Queued in Driver Trip'
    },
    {
      key: 3, value: 'Trip Initiated'
    },
    {
      key: 4, value: 'Pickup Start'
    },
    {
      key: 5, value: 'Reached at pickup'
    },
    {
      key: 6, value: 'Order Picked'
    },
    {
      key: 7, value: 'Drop Start'
    },
    {
      key: 8, value: 'Reach at drop'
    },
    {
      key: 9, value: 'Order Unloaded(Unloaded and Barcoded)'
    },
    {
      key: 10, value: 'Order ReInitiated '
    },
    {
      key: 15, value: 'Order Cancelled'
    },
    {
      key: 16, value: 'Order Cancelled due to Pickup Not Available'
    },
    {
      key: 20, value: 'Order Dropped'
    },

  ]
  Route_Action_Next = [
    {
      key: 1, value: 'Single Pickup'
    },
    {
      key: 2, value: 'Single Drop'
    },
    {
      key: 3, value: 'Group Pickup'
    },
    {
      key: 4, value: 'Group Drop'
    },
    {
      key: 5, value: 'Single Pickup Hub'
    },
    {
      key: 6, value: 'Single Drop Hub'
    },
    {
      key: 7, value: 'Group Pickup Hub'
    },
    {
      key: 8, value: 'Group Drop Hub'
    }

  ]
  Route_Status = [
    {
      key: 1, value: 'Route Created'
    },
    {
      key: 2, value: 'Pickup/Drop Started'
    },
    {
      key: 3, value: 'Reached Location'
    },
    {
      key: 4, value: 'Load/Unload Packages'
    },
    {
      key: 5, value: 'Route Completed '
    },
    {
      key: 6, value: 'Route Cancelled'
    }
  ]

  public readonly Url =this.getURL(1);
  public readonly ImageUpload_Url = this.getURL(2);
  public readonly commonUrl = this.getURL(3);

  getURL(Type) {

    //Type--->1. main host  2.image or file host 3. common
console.log(window.location.hostname)
    if (window.location.hostname == "adminoct.sagiam.com" || window.location.hostname == "oapi.greenshufflelogistics.com" ) {
      if (Type == 1) {
        return "https://oapi.greenshufflelogistics.com/admin/";
      } else if (Type == 2) {
        return "https://oapi.greenshufflelogistics.com/upload/";
      } else if (Type == 3) {
        return "https://oapi.greenshufflelogistics.com/common/";
      }
    } else {
      if (Type == 1) {
        return "https://oapi.greenshufflelogistics.com/admin/";
      } else if (Type == 2) {
        return "https://oapi.greenshufflelogistics.com/upload/";
      } else if (Type == 3) {
        return "https://oapi.greenshufflelogistics.com/common/";
      }
    }
  }
  limit = 10;
  AdminData = {};
  VechileTypes = [
    {
      name: 'Two-Wheeler',
      value: 1
    },
    {
      name: 'Three-Wheeler',
      value: 2
    },
    {
      name: 'Four-Wheeler',
      value: 3
    }
  ]
  DimentionsType = [
    {
      name: "inches", value: "1"
    },
    {
      name: "mts", value: "2"
    },
    {
      name: "kms", value: "3"
    },
    {
      name: "yards", value: "4"
    },
    {
      name: "feets", value: "5"
    }
  ]
  weightTypes = [
    {
      name: "Kg", value: "1"
    },
    {
      name: "pounds(lbf)", value: "2"
    },
    {
      name: "newton", value: "3"
    }
  ]
  Discount_Type = [
    {
      name: "Variable", value: "1"
    },
    {
      name: "Flat", value: "2"
    }
  ]
  Payment_Type = [
    {
      name: "Task", value: "1"
    },
    {
      name: "Trip", value: "2"
    }
  ]

  constructor(private httpService: HttpClient) { }
  findWeight(num: number) {
    let fetch = this.weightTypes.find(item => item.value == String(num));
    return fetch.name;

  }
  getMethod(url): any {
    return this.httpService.get(url)
  }
  getSubOrderStatus(key: number) {
    let item = this.subOrderStatus.find((item) => item.key == key)
    return item.value
  }
  getTotalTripStatus(key: number) {

    let item = this.Total_Trip_Status.find((item) => item.key == key)
    return item.value
  }
  getRoute_Action_next(key: number) {
    let item = this.Route_Action_Next.find((item) => item.key == key)
    return item.value
  }
  getRoute_status(key: number) {
    let item = this.Route_Status.find((item) => item.key == key)
    return item.value
  }
  getOrder_Type_Value(key) {
    let item = this.Order_Type.find((item) => item.key == key)
    return item.value
  }
  getSub_Trip_Type_Value(key) {
    let item = this.Sub_Trip_Type.find((item) => item.key == key)
    return item.value
  }
  getCustomerName(type) {
    let index = this.Customer_Target_Type.findIndex((item) => item.id == type)
    return this.Customer_Target_Type[index].value
  }
  getValue_DirverRule(key, type) {
    let sampleArray = []
    if (type == 1) {
      sampleArray = this.Driver_Type
    }
    else if (type == 2) {
      sampleArray = this.Period_Consideration
    }
    else if (type == 3) {
      sampleArray = this.Pay_Type
    }
    else if (type == 4) {
      sampleArray = this.Base_Qualifier
    }
    else if (type == 2) {
      sampleArray = this.Period_Consideration
    }
    else if (type == 2) {
      sampleArray = this.Period_Consideration
    }
    else if (type == 2) {
      sampleArray = this.Period_Consideration
    }
    let item = sampleArray.find((item) => item.key == key)
    return item.value
  }
  postMethod(posturl: string, body: any): Observable<any> {
    return this.httpService.post(this.Url + posturl, body)
  }
  postMethodCommon(posturl: string, body: any): any {
    return this.httpService.post(this.commonUrl + posturl, body)
  }

}
